<template>
  <div>
    <div :class="{'container': isNotMobile}">
      <h3 class="mr-3 ml-3" style="text-align: center">{{str.rain.commercial_product_info}}</h3>
      <div class="form-group text-center form-group-submit mr-3 ml-3">
        <button v-bind="buttonAttrs" @click="vacationLink">
          {{str.rain.private_product_link}}
        </button>
      </div>
    </div>
    <hr/>
    <mainComponent
      :currentCrop.sync="currentCrop"
      :data="mainSection"
      :volksfests="str.rain.volksfests"
      :timesSelector="true"
      :initDatesSynced.sync="initDates"
      :formData.sync="formData"
      @videomodal="modals.video.showed = true"
      @order="onOrder"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import common from '@/mixins/common';
import changeLang from '@/mixins/changeLang';
import partnerCodeMixin from '@/mixins/partner-code';
import { postOrderProduct } from '@/api/order';
import wpSection from '@/enums/wpSection';
import { sections, rain, buy } from '@/enums/stringIndices';

export default {
  mixins: [common, partnerCodeMixin, changeLang],
  components: {
    mainComponent: () => import(/* webpackChunkName: "mainComponent" */ '@/components/sections/main/mainIndex.vue'),
  },
  data() {
    return {
      countryCode: 'DE',
      formData: {},
      productsApi: '',
      modals: {
        video: {
          showed: false,
        },
      },
      str: {
        sections,
        rain,
        buy,
      },
      searchBotIndex: true, /** allow search index for robots */
      currentCrop: '',
      initDates: [],
    };
  },
  computed: {
    ...mapState(['menu', 'langs', 'lang', 'logo', 'wpBaseUrl', 'strings']),
    ...mapState({
      crops: (state) => state.settings.crops,
    }),
    mainSection() {
      return {
        title: this.str.rain.product_title,
        form: {
          product_title: this.str.rain.product_select_title,
          current: this.$route.query.business_type || '',
        },
        button: this.str.rain.insure_btn,
        buttonDataAttr: {
          name: 'data-business_type',
          value: this.formData.crop,
        },
      };
    },
    isNotMobile() {
      console.log('ratio', window.innerWidth, window.innerHeight);
      return window.innerWidth > window.innerHeight;
    },
    buttonAttrs() {
      return {
        id: 'main-two-button',
        class: 'wet-button wet-button-up wet-button-xs-full empty-ph',
        style: 'min-width:11rem',
      };
    },
  },
  created() {
    this.init();
  },
  watch: {
    crops: {
      immediate: true,
      handler(crops) {
        const cropIds = crops.map((c) => c.id);
        if (cropIds.length > 0 && !cropIds.includes(this.currentCrop)) {
          [this.currentCrop] = cropIds;
        }
      },
    },
  },
  methods: {
    init() {
      const {
        business_type,
        dates,
        start_date,
        end_date,
      } = this.$route.query;
      if (business_type) this.currentCrop = business_type;

      const startValid = this.$moment(start_date, 'YYYY-MM-DD', true).isValid();
      const endValid = this.$moment(end_date, 'YYYY-MM-DD', true).isValid();
      const intervalValid = this.$moment(start_date, 'YYYY-MM-DD').isBefore(this.$moment(end_date, 'YYYY-MM-DD'))
        || start_date === end_date;

      if (dates) {
        // set individual dates
        const datesArray = dates.split(',')
          .filter((d) => this.$moment(d, 'YYYY-MM-DD', true).isValid())
          .map((d) => this.$moment(d, 'YYYY-MM-DD', true));
        this.initDates = Array.from(datesArray);
      } else if (startValid && endValid && intervalValid) {
        // set interval
        const datesArray = this.$moment.range(this.$moment(start_date, 'YYYY-MM-DD'), this.$moment(end_date, 'YYYY-MM-DD')).by('days');
        this.initDates = Array.from(datesArray);
      }

      Promise.all([
        this.getWordPressStrings(
          [
            wpSection.RAIN,
            wpSection.SECTIONS,
            wpSection.BUY,
          ],
        ),
        // set crops and calendar settings
        this.$store.dispatch('settings/getProductSettings', this.$route.name),
      ])
        .then(() => {
          this.setCrops();
        });
    },
    order() {
      // convert data
      const hoursRange = this.formData.hours;
      const hours = [...Array(Math.max(...hoursRange) - Math.min(...hoursRange))]
        .map((el, i) => i + Math.min(...hoursRange));
      const data = {
        business: {
          type: this.formData.crop,
        },
        dates: this.formData.dates?.map((date) => this.$moment(date).format('YYYY-MM-DD')),
        hours,
        partner_code: this.partnerCode,
      };

      if (`${this.$route.query.keep_order}` === '1') {
        data.keep_order = true;
      }

      if (this.formData.crop === 'volksfest') {
        data.business.volksfest_id = this.formData.volksfest.id;
        data.business.country_code = this.countryCode;
      }

      // send
      return postOrderProduct(data);
    },
    setCrops() {
      const crops = this.str.rain.products.map((el) => ({
        id: el.id,
        title: el.title,
        photo_url: el.photo.url,
        photo_srcset: el.photo.sizes,
      })) || [];
      this.$store.commit('settings/SET_CROPS', crops);
    },
    async onOrder() {
      const modal = {
        id: 'wet-buy-product-not-supported',
        content: `<div class="text-center">${this.str.buy.error_product_not_active}</div>`,
        actions: [
          {
            name: 'wet-buy-product-not-supported',
            title: this.strings.commonApp?.ok,
            event: 'close',
          },
        ],
        close: this.onCloseModal,
      };
      this.$store.dispatch('addModal', modal);
      /**
      await this.$login();
      await this.order()
        .then((res) => {
          let name = 'location';

          if (!this.$route.query?.redirect || `${this.$route.query.keep_order}` !== '1') {
            if (this.formData.crop === 'volksfest') {
              if (['5'].includes(res.data.user?.status?.toString())) {
                name = 'letus';
              } else name = 'done';
            }
          } else name = this.$route.query?.redirect;

          // redirect if success
          this.$router.push({ name, params: { ...this.$route.params } });
        });
        */
    },
    vacationLink() {
      this.$router.push({ query: null, name: 'vacation', params: { ...this.$route.params } });
    },
  },
};
</script>
